import { useLocation } from "@reach/router"
import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { Helmet } from "react-helmet"
import { SEOdefaultsQuery } from "../../__generated__/graphql-gatsby"

export interface IOgImage {
  url: Maybe<string>
  alt: Maybe<string>
  width?: Maybe<number>
  height?: Maybe<number>
}

export interface SEOProps {
  title?: Maybe<string>
  description?: Maybe<string>
  ogTitle?: Maybe<string>
  ogDescription?: Maybe<string>
  img?: IOgImage
  noRobots?: boolean
  isArticle?: boolean
  canonical?: Maybe<string>
  language?: Maybe<string>
  siteName?: Maybe<string>
}

const SEO: React.FC<SEOProps> = ({
  title,
  ogTitle,
  description,
  ogDescription,
  img,
  noRobots,
  isArticle,
  canonical,
  language,
  siteName,
}) => {
  const { pathname } = useLocation()
  const { wp } = useStaticQuery<SEOdefaultsQuery>(graphql`
    query SEOdefaults {
      wp {
        generalSettings {
          title
          description
          language
        }
        seo {
          schema {
            siteUrl
            inLanguage
            logo {
              sourceUrl
              mediaDetails {
                width
                height
              }
            }
          }
        }
      }
    }
  `)

  const defaultTitle = title ? title : wp?.generalSettings?.title || `Pop Up Pallets`
  const metaLanguage = wp?.seo?.schema?.inLanguage ? wp.seo.schema.inLanguage.toUpperCase() : `NL`

  const defaultDescription = description
    ? description
    : wp?.generalSettings?.description || `Modulair pallet interieur`

  const defaultImg: IOgImage = {
    url: img?.url ? img.url : String(wp?.seo?.schema?.logo?.sourceUrl),
    alt: img?.alt ? img.alt : `PUP Logo`,
    width: img?.width ? img.width : Number(wp?.seo?.schema?.logo?.mediaDetails?.width),
    height: img?.height ? img.height : Number(wp?.seo?.schema?.logo?.mediaDetails?.height),
  }

  const seo = {
    title: defaultTitle,
    ogTitle: ogTitle ? ogTitle : defaultTitle,
    description: defaultDescription,
    ogDescription: ogDescription ? ogDescription : defaultDescription,
    canonicalURL: canonical
      ? `${wp?.seo?.schema?.siteUrl}${canonical}`
      : `${wp?.seo?.schema?.siteUrl}${pathname}`,
    img: defaultImg,
    language: language ? language : wp?.generalSettings?.language || `nl_NL`,
    robots: noRobots ? `nofollow,noindex` : `follow,index`,
    siteName: siteName ? siteName : wp?.generalSettings?.title || `Pop Up Pallets`,
  }

  return (
    <Helmet
      htmlAttributes={{
        lang: seo.language,
        title: seo.title,
      }}
    >
      <meta httpEquiv="language" content={metaLanguage} />
      <link rel="canonical" href={seo.canonicalURL} />

      <title>{seo.title}</title>
      <meta name="description" content={seo.description} />
      <meta name="image" content={String(seo.img.url)} />

      <meta name="og:url" content={seo.canonicalURL} />
      <meta name="og:type" content={isArticle ? `article` : `website`} />
      <meta name="og:title" content={seo.ogTitle} />
      <meta name="og:description" content={seo.ogDescription} />
      <meta name="og:locale" content={seo.language} />
      <meta name="og:site_name" content={seo.siteName} />

      <meta name="og:image" content={String(seo.img.url)} />
      <meta name="og:image:secure" content={String(seo.img.url)} />
      <meta name="og:image:alt" content={String(seo.img.alt)} />
      <meta name="og:image:width" content={String(seo.img.width)} />
      <meta name="og:image:height" content={String(seo.img.height)} />

      <meta name="robots" content={seo.robots} />
      <meta name="googlebot" content={seo.robots} />
    </Helmet>
  )
}

export default SEO
